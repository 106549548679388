<template>
	<div class="row small pointer">
		<div class="col-12">
			<div class="card-img-container">
				<img :src="item.image?.downloadURL" class="card-img" alt="...">
			</div>
		</div>
		<div class="col-12">
			<div class="p-2">
				<div class="text-danger" v-if="item.flagged">
					This item and/or the user who posted this trip has been flagged by other users.
				</div>
				<div class="col-12" v-else>
					<div class="item-caption rounded-pill text-white badge mb-2" :class="badgeColor(item.post_type)">
						{{item.vacation_type}}
					</div>
					<p class="excerpt">
						Seeking {{ item.total_guests }} person(s) to go to {{ destinations.find((destination) => destination.id === item.destination).name }} for {{ vacation_types.find((vacation_type) => vacation_type.id === item.vacation_type).name }} 
						from {{ formatDate(item.start_date, "MMM dd, yyyy") }} to {{ formatDate(item.end_date, "MMM dd, yyyy") }}.
					</p>
					<div class="item-caption small text-secondary">
						&bull; {{item.destination}}
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import { formatDate } from "../../utils";
import { destinations, vacation_types } from '../../data';
export default {
	name: "CrewfinderContent",
	props:{
		item: {
			type: Object,
			required: true,
		}
	},
	data(){
		return{
			isBusy: false,
			filter: {
				category: "",
				country: "",
				post_type: "",
			},
			activeItem: { },
			formatDate,
			destinations, 
			vacation_types,
		}
	},
	methods:{
		badgeColor(post_type){
			switch(post_type){
				case "selling":
					return "bg-info";
				case "buying":
					return "bg-warning";
				default:
					return "bg-info";
			}
		},
	}
}
</script>
<style scoped>
@media only screen and (max-width: 600px) {
	.excerpt{
		font-size: 1.2rem;
	}
}
</style>