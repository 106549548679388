<template>
	<div class="row mb-5">
		<FloatingActionButton class="d-lg-none" data-bs-toggle="offcanvas" data-bs-target="#crewfinderFilters"
			aria-controls="marketplaceFilter" />
		<div v-if="stagingCrewfinder.length > 0" class="alert alert-primary pointer" align="center" @click="alertClick">
			<span v-if="stagingCrewfinder.length == 1">View 1 new listing.</span>
			<span v-if="stagingCrewfinder.length > 1">View {{ stagingCrewfinder.length }} new listings.</span>
		</div>
		<div class="col-12 col-lg-3 order-md-last d-none d-lg-block" style="position: releative;">
			<div class="hide-scrollbar border-start border-light p-lg-3"
				style="position: fixed; height: 90vh; width:23vw; overflow-y: auto;">
				<CrewfinderFilter />
			</div>
		</div>
		<div class="col-12 col-lg-9 mb-5 py-md-5">
			<div class="h1 mb-4 d-md-none">
				Crewfinder
			</div>
			<!-- 			<PageCallOut :title="`COVID-19: Buyers and Sellers`"
				:body="`Please follow local guidelines about physical distancing and staying home.`" />
 -->
			<PostSkeleton v-if="!Array.isArray(posts)" />
			<p class="my-5 text-muted" align="center" v-else-if="Array.isArray(posts) && posts.length < 1">
				<em><i class="bi bi-people"></i> Travellers will appear here...</em>
			</p>
			<div class="row" v-else>
				<template v-for="item in posts" :key="item.id">
					<div class="col-12 col-md-6 col-lg-4 my-2" data-bs-toggle="modal" data-bs-target="#viewCrewfinderPost"
						@click="activeItem = item">
						<CrewfinderContent :item="item" />
					</div>
				</template>
			</div>
			<!-- Modal -->
			<div class="modal fade" id="viewCrewfinderPost" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
				aria-labelledby="viewCrewfinderPostLabel" aria-hidden="true">
				<div class="modal-dialog modal-dialog-scrollable modal-xl">
					<div class="modal-content">
						<div class="modal-header pb-0">
							<h5 class="modal-title" id="viewCrewfinderPostLabel"> </h5>
							<button type="button" class="btn-close m-1" data-bs-dismiss="modal" aria-label="Close"></button>
						</div>
						<div class="modal-body">
							<CrewfinderDetails :activeItem="activeItem" :key="activeItem.id" />
						</div>
					</div>
				</div>
			</div>

			<!-- Off Canvas -->
			<div class="offcanvas offcanvas-end" data-bs-scroll="true" tabindex="-1" id="crewfinderFilters"
				aria-labelledby="crewfinderFilterLabel">
				<div class="offcanvas-header">
					<button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas"
						aria-label="Close"></button>
				</div>
				<div class="offcanvas-body">
					<CrewfinderFilter />
				</div>
			</div>


		</div>
	</div>
</template>

<script>
import { formatDate } from "../utils";
import { destinations, vacation_types } from '../data';
import CrewfinderDetails from "../components/crewfinder/CrewfinderDetails.vue";
import FloatingActionButton from "../components/common/FloatingActionButton.vue";
import CrewfinderFilter from '../components/crewfinder/CrewfinderFilter.vue';
import PageCallOut from '../components/common/PageCallOut.vue';
import PostSkeleton from '../components/common/PostSkeleton.vue';
import CrewfinderContent from '../components/crewfinder/CrewfinderContent.vue';

export default {
	name: "Crewfinder",
	components: {
		CrewfinderDetails,
		FloatingActionButton,
		PageCallOut,
		PostSkeleton,
		CrewfinderFilter,
		CrewfinderContent,
	},
	computed: {
		userProfile() {
			return this.$store.state.userProfile;
		},
		isLoggedIn() {
			return this.$store.state.isLoggedIn;
		},
		posts() {
			const crewfinder = this.$store.state.crewfinder;
			if (Array.isArray(crewfinder)) {
				if (this.filter.post_type == "" && this.filter.category == "" && this.filter.country == "") return crewfinder;
				const filter = (item) => {
					return (item.post_type == this.filter.post_type &&
						item.category == this.filter.category &&
						item.country == this.filter.country);
				}
				return crewfinder.filter(filter);
			} else {
				return crewfinder;
			}
		},
		stagingCrewfinder() {
			return this.$store.state.stagingCrewfinder;
		},
		msgCount() {
			const messages = this.$store.state.messages;
			if (Array.isArray(messages)) {
				return messages.filter((msg) => !msg.read.includes(this.userProfile.id));
			} else {
				return [];
			}
		},
	},
	data() {
		return {
			isBusy: false,
			filter: {
				category: "",
				country: "",
				post_type: "",
			},
			activeItem: {},
			formatDate,
			destinations,
			vacation_types,
		}
	},
	methods: {
		btnFilterClick() {
			this.filter = {
				category: "",
				country: "",
				post_type: "",
			}
			this.showBtnClearFilter = false;
		},
		alertClick() {
			this.$store.dispatch("loadCrewfinderPosts");
			window.scrollTo(0, 0);
		},
	},
	updated() {

	},
	mounted() {
		this.$store.commit("setPageTitle", "Crewfinder")

	}

}
</script>
<style scoped>
@media only screen and (max-width: 600px) {
	.excerpt {
		font-size: 1.2rem;
	}
}
</style>