<template>
	<div class="row mb-5">
		<FloatingActionButton class="d-lg-none " clickActionUrl="/crewfinder/add" />
		<div class="col-12 col-md-4 col-lg-3 order-md-last border-start d-none d-lg-block">
			<div style="position: fixed; height: 85vh; width:23vw; overflow-y: auto;">
				<div class="d-grid my-3 p-3">
					<button v-if="isLoggedIn" class="btn btn-dark my-2" @click="$router.push('/messages')">
						Messages ({{ msgCount.length }})
					</button>
					<button class="btn btn-dark my-2" @click="$router.push('/crewfinder/add')">
						Add new trip
					</button>
					<button class="btn btn-outline-dark my-2" @click="$router.push('/crewfinder')">
						Browse all trips
					</button>
				</div>
			</div>
		</div>
		<div class="col-12 col-md-8 col-lg-9 my-2 mb-5">
			<div class="h5 my-5"><span class="pointer" @click="$router.go(-1)"><i class="bi bi-chevron-left pointer h5 me-4"></i> </span> Listings ({{myListings.length}})</div>
			<p class="my-5 text-muted" align="center" v-if="Array.isArray(myListings) && myListings.length<1">
				<em><i class="bi bi-people"></i> Your trips will appear here...</em>
			</p>
			<div class="row">
				<div class="col-12 col-md-6 col-lg-4 my-3" v-for="item in myListings" :key="item.id">
					<CrewfinderContent :item="item" />
					<div align="right" class="d-grid">
						<button class="btn btn-sm btn-dark my-2" data-bs-toggle="modal" data-bs-target="#confirmDeleteCrewfinder" @click="docToDelete = item.id">
							Delete this item
						</button>
					</div>
					<div class="col-12 d-md-none border-bottom"> &nbsp; </div>
				</div>
			</div>
			<!-- <ul class="list-group my-4" v-for="item in myListings" :key="item.id">
				<li class="list-group-item">
					<div class="row my-3">
						<div class="col-md-9">
							<h5 class="card-title mkt-title">{{ item.title }}</h5>
							<p>&bull; {{ item.start_date }} to {{ item.end_date }}
							<br/>&bull; {{ item.guests }} {{ item.post_type.toLowerCase() }} </p>
							<p>{{ item.details }}</p>
							<p class="text-danger" v-if="item.flagged"><i class="bi bi-flag"></i> This post (and your account) was flagged by another user on {{ formatDate(item.updated) }}.</p>
							<p class="small text-secondary">Posted: {{ formatDate(item.created_at) }}</p>
						</div>
						<div class="col-md-3 my-2">
							<button class="btn btn-dark btn-sm" data-bs-toggle="modal" data-bs-target="#confirmDeleteCrewfinder" @click="docToDelete = item.id">
								<i class="bi bi-trash"></i> Delete this trip
							</button>
						</div>
					</div>
				</li>
			</ul>	 -->
		</div>

		<!-- Modal -->
		<div class="modal fade" id="confirmDeleteCrewfinder" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="confirmDeleteCrewfinderLabel" aria-hidden="true">
			<div class="modal-dialog modal-dialog-centered">
				<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title" id="confirmDeleteCrewfinderLabel">Confirm delete</h5>
					<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
				</div>
				<div class="modal-body">
					Are you sure you want to delete this trip?
				</div>
				<div class="modal-footer">
					<button id="btnCancelDelete" type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
					<button type="button" class="btn btn-dark" @click="confirmDeleteClick">
						<span v-show="!isBusy">Continue</span>
						<div class="spinner-border text-light" role="status" v-show="isBusy">
							<span class="sr-only">Loading...</span>
						</div>
					</button>
				</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { useToast } from "vue-toastification";
import { doc, deleteDoc } from "firebase/firestore";
import { db, collections } from "../firebaseConfig";
import { formatDate } from "../utils";
import FloatingActionButton from "../components/common/FloatingActionButton.vue";
import CrewfinderContent from "../components/crewfinder/CrewfinderContent.vue";
export default {
	name: "CrewfinderList",
	components: {
		FloatingActionButton,
		CrewfinderContent,
	},
	setup(){
		const toast = useToast();
		return { toast };
	},
	computed:{
		userProfile(){
			return this.$store.state.userProfile
		},
		isLoggedIn(){
			return this.$store.state.isLoggedIn;
		},
		myListings(){
			const crewfinder = this.$store.state.crewfinder;
			if(Array.isArray(crewfinder)){
				return (crewfinder.filter((item) => item.author.id == this.userProfile.id));
			}else{
				return [];
			}
		},
		msgCount(){
			const messages = this.$store.state.messages;
			if(Array.isArray(messages)){
				const filter = (msg)=>{
					return !msg.read.includes(this.userProfile.id);
				}
				return messages.filter(filter);
			}else{
				return [];
			}
		},
	},
	data(){
		return{
			formatDate,
			isBusy: false,
		}
	},
	methods:{
		async confirmDeleteClick(){
			this.isBusy = true;
			try{
				await deleteDoc(doc(db, collections.CREWFINDER, this.docToDelete));
				this.toast.success("Trip deleted.", {
					timeout: 3000
				});
			}catch(error){
				console.log(error);
				this.toast.error("Error deleting item.", {
					timeout: 3000
				});
			}finally{
				this.isBusy = false;
				document.getElementById("btnCancelDelete").click();
			}
		},
	},
	mounted(){
		this.$store.commit("setPageTitle", "my-listings")
		
	}
}
</script>
