<template>
	<div class="row">
		<div class="col-lg-5 mb-3 px-lg-4">
			<img :src="activeItem.image?.downloadURL" class="card-img sticky-top" alt="...">
		</div>
		<div class="col-lg-7 mb-5" v-if="activeItem.destination">
			<div class="alert alert-danger my-3" v-if="activeItem.flagged">
				<i class="bi bi-flag"></i> This post (and user account) has been flagged by another user. You are strongly encouraged to ignore this post.
			</div>
			<div class="item-caption text-secondary mb-3" style="text-transform: lowercase;">{{timeAgo(activeItem.created_at)}} by</div>
			<div class="my-2 mb-3 row">
				<AvatarPosts :author="activeItem.author" />
			</div>
			<div class="my-2 mb-4" v-if="!activeItem.flagged">
				<ItemActionBar :activeItem="activeItem" :dbCollection="collections.CREWFINDER" :key="activeItem.id" />
			</div>
			<div class="my-3">
				<p class="h5">Seeking</p>
				<div class="my-2">
					&bull; {{ activeItem.total_guests }} guest(s) in total. <br/>
					<span class=" text-capitalize">
					&bull; {{ activeItem.vacation_gender.join(", ") }}.</span>
					
				</div>
			</div>
			<div class="my-4">
				<p class="h5 ">Trip details</p>
				<div class="my-2 text-capitalize">
					<p><span class="small text-secondary">Dates:</span> <br/>{{ formatDate(activeItem.start_date, "MMM dd, ''yy") }} - {{ formatDate(activeItem.end_date, "MMM dd, ''yy") }} </p>
					<p><span class="small text-secondary">Vacation type /Destination:</span>  <br/>{{ activeItem.vacation_type }} in {{ activeItem.destination }}</p>
				</div>
				{{activeItem.details}}
			</div>
			<div class="my-4">
				<p class="h5">Trip requirements</p>
				{{activeItem.trip_requirements}}
			</div>
			<div class="my-4">
				<p class="h5">What is it like to travel with me</p>
				{{activeItem.travelling_with_me}}
			</div>
			<div class="my-4">
				<p class="h5">What I look for in a travel buddy</p>
				{{activeItem.travel_buddy_requirements}}
			</div>
			<div class="my-4">
				<p class="h5">About me</p>
				<p class="mb-4 text-capitalize">
					&bull; Age: {{getAge(activeItem.author?.birth_year)}} <br/>
					&bull; Gender: {{activeItem.author?.gender}} <br/>
					&bull; Profession: {{activeItem.profession}} <br/>
					&bull; Country: {{activeItem.author?.country}} <br/>
				</p>
				<p class="my-4">
					<span class="h5">Interests </span><br/>
					<span class="badge rounded-pill bg-dark mt-3 me-2 text-uppercase"  
					v-for="(interest, index) in activeItem.interests" 
					:key="index">{{ interest }}</span>
				</p>
				<p class="my-4">
					<span class="h5">Fun fact: </span><br/>
					{{activeItem.fun_fact}}
				</p>
			</div>
			<SendMessageBox class="mt-4 mb-5" v-if="activeItem.author.id !== userProfile.id" 
			:flagged="activeItem.flagged" 
			:author="activeItem.author"
			:item="`${activeItem.vacation_type?.toUpperCase()} in ${activeItem.destination?.toUpperCase()}`"/>
		</div>
	</div>
</template>
<script>
import { 
	timeAgo, 
	formatDate, 
	formatCurrency,
	getAge,
} from "../../utils";
import { collections } from "../../firebaseConfig";
import AvatarPosts from '../common/AvatarPosts.vue';
import ItemActionBar from "../common/ItemActionBar.vue";
import SendMessageBox from "../common/SendMessageBox.vue";
export default {
	name: "CrewfinderDetails",
	props: {
		activeItem: {
			type: Object,
			required: true,
		}
	},
	components: {
		ItemActionBar,
		AvatarPosts,	
		SendMessageBox,
	},
	computed:{
		userProfile(){
			return this.$store.state.userProfile
		},
		imgPlaceholder(){
			return this.$store.state.imgPlaceholder;
		},
	},
	data(){
		return{
			isBusy: false,
			collections,
			timeAgo,
			formatDate,
			formatCurrency,
			getAge,
		}
	},
	methods:{

	},
	mounted(){
		this.$store.commit("setPageTitle", "Marketplace");
	}

}
</script>