<template>
	<div class="row mb-5 pt-1">	
		<div class="h3 my-3">Crewfinder</div>
		<div class="col-12 d-grid">
			<button v-if="isLoggedIn" class="btn btn-dark my-2" @click="$router.push('/messages')">
				Messages ({{ msgCount.length }})
			</button>
			<button class="btn btn-dark my-2" @click="$router.push('/crewfinder/add')">
				Create new listing
			</button>
			<button class="btn btn-outline-dark my-2 mb-4" @click="$router.push('/crewfinder/list')">
				View my listings
			</button>
			<div class="h5">Looking for</div>
			<div class="form-check box20vw ms-3" v-for="(gender, index) in vacation_gender" :key="index">
				<input class="form-check-input" type="checkbox" :value="gender.id" :id="`gender${index}`">
				<label class="form-check-label" :for="`gender${index}`">
					{{ gender.name }}
				</label>
			</div>
			<div class="h5 mt-4">Vacation types</div>
			<div class="form-check ms-3" v-for="(vacation_type, index) in vacation_types" :key="index">
				<input class="form-check-input" type="checkbox" :value="vacation_type.id" :id="`vacation_type${index}`">
				<label class="form-check-label" :for="`vacation_type${index}`">
					{{ vacation_type.name }}
				</label>
			</div>
			<div class="h5 mt-4">Destinations</div>
			<div class="form-check box20vw ms-3" v-for="(country, index) in destinations" :key="index">
				<input class="form-check-input" type="checkbox" :value="country.id" :id="`country${index}`">
				<label class="form-check-label" :for="`country${index}`">
					{{ country.name }}
				</label>
			</div>
		</div>
	</div>
</template>

<script>
import {
	destinations, 
	vacation_types,
	vacation_gender,
} from '../../data';
export default {
	name: "CrewfinderFilter",
	components: {

	},
	computed:{
		isLoggedIn(){
			return this.$store.state.isLoggedIn;
		},
		userProfile(){
			return this.$store.state.userProfile;
		},
		msgCount(){
			const messages = this.$store.state.messages;
			const profileId = this.userProfile.id;
			if(Array.isArray(messages) && profileId){
				return messages.filter((msg)=> !msg.read.includes(profileId));
			}else{
				return [];
			}
		},
	},
	data(){
		return{
			filter:[],
			destinations, 
			vacation_types,
			vacation_gender,
		}
	},
	methods:{
		btnFilterClick(){
			this.filter = {
				category: "",
				country: "",
				post_type: "",
			};
		},
		onFilterChange(){
			this.showBtnClearFilter = true;
		},
		itemClick(selectedItem, selectedId){
			this.activeItem = selectedItem;
			this.activeItemId = selectedId;
			this.activeItemAuthor = selectedItem.author;
			this.marketplaceComponentKey = Date.now();
		},
		alertClick(){
			this.$store.dispatch("loadMarketplacePosts");
			window.scrollTo(0,0);
		},
		likeButtonClick(){

		},
	},
	mounted(){
		this.$store.commit("setPageTitle", "Marketplace");
	}

}
</script>
<style scoped>
	.box20vw{
		white-space: normal;
	}
</style>