<template>
	<div>
		<div class="row px-2">
			<div class="col-12 col-lg-3">
				
			</div>
            <div class="col-12 col-lg-6">
				<div class="mb-5 my-4 h5 pointer" @click="$router.push('/crewfinder')">
						<i class="bi bi-chevron-left"></i> Back
				</div>
                <div class="row" >
					<p class="h4 fw-bold">New trip</p>
					<div class="col-12 my-2">
						<label class="text-muted small">Looking for:*</label>
						<div class="form-check my-2 ms-3" v-for="(gender, index) in vacation_gender" :key="index">
							<input class="form-check-input" type="checkbox" :value="gender.id" v-model="item.vacation_gender" :id="`gender${index}`">
							<label class="form-check-label" :for="`gender${index}`">
								{{gender.name}}
							</label>
						</div>
					</div>
                    <div class="col-12 my-2">
						<label class="text-muted small">To where are you travelling?*</label>
						<select class="form-select" v-model="item.destination">
							<option v-for="destination in destinations" :key="destination.id" :value="destination.id">{{destination.name}}</option>
						</select>
					</div>
					<div class="col-12 my-2">
						<label class="text-muted small">What type of trip is it?*</label>
						<select class="form-select" v-model="item.vacation_type">
                            <option v-for="vacation_type in vacation_types" :key="vacation_type.id" :value="vacation_type.id">
								{{vacation_type.name}}
							</option>
						</select>
					</div>
                    <div class="col-12 my-2">
						<label class="text-muted small">How many persons do you need for your trip?*</label>
						<input type="number" class="form-control" v-model.number="item.total_guests"/>
					</div>
					<div class="col-12 my-2">
                       <label class="text-muted small">When is the departure date of the trip?*</label>
						<input type="date" class="form-control" v-model="item.start_date"/>
					</div>
                    <div class="col-12 my-2 mb-3">
                        <label class="text-muted small">When is the return date of the trip?*</label>
						<input type="date" class="form-control" v-model="item.end_date" />
					</div>
					<div class="col-12 my-2">
						<label class="text-muted small">What are the details of the trip?*</label>
						<textarea rows="7" class="form-control" wrap="hard" placeholder="Provide details for your trip" v-model="item.details"/>
					</div>
					<div class="col-12 my-2">
						<label class="text-muted small">What is needed for this trip?*</label>
						<textarea rows="7" class="form-control" wrap="hard" placeholder="List any special requirements for this trip" v-model="item.trip_requirements"/>
					</div>
					<div class="col-12 my-2">
						<label class="text-muted small">What is it like to travel with you?*</label>
						<textarea rows="7" class="form-control" wrap="hard" placeholder="Travelling with me is..." v-model="profile.travelling_with_me"/>
					</div>
					<div class="col-12 my-2">
						<label class="text-muted small">What are you looking for in a travel buddy?*</label>
						<textarea rows="7" class="form-control" wrap="hard" placeholder="I look for the following in a travel buddy..." v-model="profile.travel_buddy_requirements"/>
					</div>
					<div class="col-12 mt-4">
						<div class="h5">
							About you
						</div>
					</div>
					<div class="col-12 my-2">
						<label class="text-muted small">What is your relationship status?*</label>
						<select class="form-select" v-model="profile.relationship_status">
							<option v-for="status in relationship_status" :key="status.id" :value="status.id">{{status.name}}</option>
						</select>
					</div>
					<div class="col-12 my-2">
						<label class="text-muted small">What is your profession?*</label>
						<select class="form-select" v-model="profile.profession">
							<option v-for="job in professions" :key="job.id" :value="job.id">{{job.name}}</option>
						</select>
					</div>
					<div class="col-12 my-2">
						<label class="text-muted small">What are your interests?*</label>
						<div class="form-check my-2 ms-3" v-for="(interest, index) in profile_interests" :key="index">
							<input class="form-check-input" type="checkbox" :value="interest.id" v-model="profile.interests" :id="`interest${index}`">
							<label class="form-check-label" :for="`interest${index}`">
								{{interest.name}}
							</label>
						</div>
					</div>
					<div class="col-12 my-2">
						<label class="text-muted small">What is a fun fact about you?*</label>
						<textarea rows="4" class="form-control" v-model="profile.fun_fact" />
					</div>

					<div class="col-12 mt-4">
						<div class="h5">
							Photo
						</div>
						<label class="text-muted small">Upload a photo from a similar trip or a photo of yourself on vacation.*</label>
						<ImageUploader  @setDownloadUrl="setDownloadUrl" />
					</div>

					<div class="col-12 mt-4">
						<div class="h5">
							Legal*
						</div>
					</div>
					<div class="col-12 my-2 mb-5">
						<div class="form-check mb-2">
							<input class="form-check-input" type="checkbox" value="true" id="dataConsent" v-model="item.data_consent">
							<label class="form-check-label" for="dataConsent">
								I consent to Soca Islands processing my data.
							</label>
						</div>
						<div class="form-check">
							<input class="form-check-input" type="checkbox" value="true" id="dataTerms" v-model="item.agree_terms">
							<label class="form-check-label" for="dataTerms">
								I agree the <a  class="text-dark" href="https://socaislands.com/legal-stuff" target="_terms">terms and conditions of Soca Islands's Crewfinder.</a>
							</label>
						</div>
					</div>
					<div  class="col-12 p-2 d-grid" >
						<button class="btn btn-dark btn-lg" :disabled="!isFormValid || isBusy" @click="btnSubmitClick">
							<span v-show="!isBusy">Submit trip</span>
							<div class="spinner-border text-light" role="status" v-show="isBusy">
								<span class="sr-only">Loading...</span>
							</div>
						</button>
					</div>
				</div>
            </div>
            <div class="col-12 col-lg-2">
            </div>
		</div>
	</div>
</template>

<script>
import { 
	collection, 
	addDoc,
	updateDoc,
	doc, 
} from "firebase/firestore"; 
import { 
	collections, 
	db 
} from "../firebaseConfig";
import {
	destinations, 
	experience_categories,
	vacation_gender,
	vacation_types,
	profile_interests,
	relationship_status,
	professions,
} from '../data';
import ImageUploader from '@/components/common/ImageUploader'
export default {
	name: "CrewfinderAdd",
	components: {
		ImageUploader,
	},
	computed:{
		userProfile(){
			return this.$store.state.userProfile;
		},
		isFormValid(){
			const { item, profile } = this;
			if(item.vacation_gender.length < 1) return false;
			if(!item.vacation_type) return false;
			if(!item.destination) return false;
			if(item.total_guests < 1) return false;
			//if(item.details.length < 20) return false;
			if(!item.start_date) return false;
			if(!item.end_date) return false; 
			//if(item.trip_requirements.length < 20) return false;
			if(Object.keys(item.image).length < 1) return false;
			if(!item.data_consent) return false;
			if(!item.agree_terms) return false;

			if(profile.interests.length < 1) return false;
			if(!profile.relationship_status) return false;
			if(!profile.profession) return false;
			//if(profile.travelling_with_me.length < 20) return false;
			//if(profile.travel_buddy_requirements.length < 20) return false;
			if(!profile.fun_fact) return false;

			return true;
		},
	},
	data(){
		return{
			isBusy: false,
			item:{
				status: true,
				flagged: false,
				in_review: false,
				author: {},
				post_type: "",
				vacation_type: "",
				vacation_gender: [],
				destination: "",
				total_guests: "",
				title: "",
				details: "",
				start_date: "",
				likes:[],
				saved_by:[],
				end_date: "",
				data_consent: false,
				agree_terms: false,
				image:{},
			},
			profile:{
				relationship_status:"",
				interests: [],
				profession: "",
				fun_fact: "",
			},
			destinations, 
			experience_categories,
			vacation_gender,
			vacation_types,
			profile_interests,
			relationship_status,
			professions,
		}
	},
	methods:{
		async btnSubmitClick(){
			this.isBusy = true;
			const { item, profile, userProfile } = this,
			timeStamp = Date.now(),
			{ id, birth_year, gender, created_at, country } = this.userProfile;

			item.author = {
				id,
				name: `${userProfile.fname} ${userProfile.lname}`,
				created_at,
				birth_year,
				gender,
				country,
			};
			item.created_at = timeStamp;
			item.updated_at = timeStamp;
			try{
				await addDoc(collection(db, collections.CREWFINDER), {...item, ...profile});
				await updateDoc(doc(db, collections.PROFILES, userProfile.id), profile);
				this.$router.push("/crewfinder/list");
			}catch(error){
				console.log(error);
			}finally{
				this.isBusy = false;
			}
		},
		setDownloadUrl(data){
			this.item.image = data;
		},
	},

	mounted(){
		this.$store.commit("setPageTitle", "new-listing")
	}

}
</script>
